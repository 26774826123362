import PropTypes from 'prop-types';
import { Button, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { es } from 'date-fns/locale';

const Beneficiario = ({ parentescos, beneficiario, onChange, onRemove }) => (
  <div>
    <div style={{ display: 'flex' }}>
      <h2 style={{ flex: 1 }}>Nombre completo</h2>
      <Button color="primary" onClick={onRemove}>
        Eliminar beneficiario
      </Button>
    </div>
    <TextField
      value={beneficiario.nombre}
      onChange={e => onChange(e, 'nombre')}
      variant="outlined"
      fullWidth
    />
    <div style={{ flex: 1 }}>
      <h2>Fecha de nacimiento</h2>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <KeyboardDatePicker
          id="date-picker-dialog"
          format="dd/MM/yyyy"
          value={beneficiario.fechaNacimiento}
          onChange={date => onChange(date, 'fechaNacimiento')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          fullWidth
          inputVariant="outlined"
        />
      </MuiPickersUtilsProvider>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ flex: '1 1 60%', marginRight: '10px' }}>
        <h2>Parentesco</h2>
        <TextField
          style={{ width: '100%' }}
          select
          value={beneficiario.parentescoId}
          onChange={e => onChange(e, 'parentescoId')}
          variant="outlined"
          fullWidth
        >
          {parentescos.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.nombre}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div style={{ flex: '1 1 20%' }}>
        <h2>Porcentaje</h2>
        <TextField
          type="number"
          value={beneficiario.porcentaje}
          onChange={e =>
            onChange(
              {
                ...e,
                target: { ...e.target, value: parseInt(e.target.value, 10) },
              },
              'porcentaje',
            )
          }
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </div>
    </div>
  </div>
);

Beneficiario.propTypes = {
  beneficiario: PropTypes.shape({
    nombre: PropTypes.string,
    fechaNacimiento: PropTypes.instanceOf(Date),
    parentescoId: PropTypes.number,
    porcentaje: PropTypes.number,
  }),
  parentescos: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Beneficiario;
