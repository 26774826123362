/* eslint-disable react/no-array-index-key */
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import Beneficiario from '../Beneficiario';

const BeneficiariosForm = ({
  parentescos,
  beneficiarios,
  setBeneficiariosArray,
  onValidation,
}) => {
  const handleBeneficiarioChange = (e, field, index) => {
    const updatedBeneficiarios = [...beneficiarios];
    updatedBeneficiarios[index][field] = e.target ? e.target.value : e;
    setBeneficiariosArray(updatedBeneficiarios);
  };

  const handleAddBeneficiario = () => {
    if (beneficiarios.length < 4) {
      let updatedBeneficiarios;
      if (beneficiarios.length === 0) {
        updatedBeneficiarios = [
          {
            nombre: '',
            fechaNacimiento: new Date(),
            parentescoId: '',
            porcentaje: 100,
          },
        ];
      } else {
        const newPorcentaje = Math.floor(100 / (beneficiarios.length + 1));
        const residuo = 100 - newPorcentaje * (beneficiarios.length + 1);

        updatedBeneficiarios = beneficiarios.map((b, index) => ({
          ...b,
          porcentaje: index === 0 ? newPorcentaje + residuo : newPorcentaje,
        }));

        updatedBeneficiarios.push({
          nombre: '',
          fechaNacimiento: new Date(),
          parentescoId: '',
          porcentaje: newPorcentaje,
        });
      }
      setBeneficiariosArray(updatedBeneficiarios);
    }
  };

  const handleRemoveBeneficiario = index => {
    const updatedBeneficiarios = beneficiarios.filter((_, i) => i !== index);

    if (updatedBeneficiarios.length > 0) {
      const newPorcentaje = Math.floor(100 / updatedBeneficiarios.length);
      const residuo = 100 - newPorcentaje * updatedBeneficiarios.length;

      const updatedBeneficiariosWithPercentage = updatedBeneficiarios.map(
        (b, i) => ({
          ...b,
          porcentaje: i === 0 ? newPorcentaje + residuo : newPorcentaje,
        }),
      );

      setBeneficiariosArray(updatedBeneficiariosWithPercentage);
    } else {
      setBeneficiariosArray([]);
    }
  };

  const isBeneficiarioValid = () => {
    const totalPorcentaje = beneficiarios.reduce((total, beneficiario) => {
      const porcentaje = beneficiario.porcentaje || 0;
      return total + porcentaje;
    }, 0);

    if (totalPorcentaje !== 100 || totalPorcentaje === 0) {
      return false;
    }

    return beneficiarios.every(
      beneficiario =>
        beneficiario.nombre &&
        beneficiario.fechaNacimiento &&
        new Date().getFullYear() -
          new Date(beneficiario.fechaNacimiento).getFullYear() >=
          18 &&
        beneficiario.parentescoId &&
        beneficiario.porcentaje,
    );
  };

  useEffect(() => {
    const isAllValid =
      beneficiarios.length === 0
        ? false
        : beneficiarios.every(isBeneficiarioValid);
    onValidation(isAllValid);
  }, [beneficiarios, onValidation]);
  useEffect(() => {
    setBeneficiariosArray(beneficiarios);
  }, [beneficiarios, setBeneficiariosArray]);

  return (
    <div className="beneficiarios-form">
      {beneficiarios.map((beneficiario, index) => (
        <div key={index}>
          <h3 style={{ marginTop: '15px' }}>Beneficiario - {index + 1}</h3>
          <Beneficiario
            key={index}
            beneficiario={beneficiario}
            parentescos={parentescos}
            onChange={(e, field) => handleBeneficiarioChange(e, field, index)}
            onRemove={() => handleRemoveBeneficiario(index)}
          />
        </div>
      ))}
      {beneficiarios.length < 4 && (
        <Button color="primary" onClick={handleAddBeneficiario}>
          Agregar Beneficiario
        </Button>
      )}
    </div>
  );
};

BeneficiariosForm.propTypes = {
  parentescos: PropTypes.array,
  beneficiarios: PropTypes.array,
  setBeneficiariosArray: PropTypes.func,
  onValidation: PropTypes.func,
};

export default BeneficiariosForm;
