/* eslint-disable react/prop-types */
/**
 * TestimonialsPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import NavigateBefore from '@material-ui/icons/KeyboardArrowLeft';
import NavigateAfter from '@material-ui/icons/KeyboardArrowRight';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useMediaQuery } from '@material-ui/core';
import { Content, ItemTestimonial } from './styledComponents';
import { media } from '../../helper';

export default function Testimonials({ list }) {
  const isSmall = useMediaQuery(media.medium);
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    swipeToSlide: true,
    centerMode: true,
    className: 'custom-slider',
    centerPadding: '10px',
    nextArrow: <NavigateAfter />,
    prevArrow: <NavigateBefore />,
  };

  return (
    <Content>
      <Slider slidesToShow={3} dots={isSmall} {...settings}>
        {list.map(item => (
          <>
            <ItemTestimonial>
              <div className="content">
                <div className="top">
                  <div className="avatar-section">
                    <img className="avatar" src={item.avatar} alt="" />
                    {item.showLevelIcon && (
                      <img className="level" src="./images/level.png" alt="" />
                    )}
                  </div>
                  <div>
                    <div className="user-name">{item.name}</div>
                    <div className="level">{item.level}</div>
                  </div>
                </div>
                <div className="text">{item.text}</div>
              </div>
            </ItemTestimonial>
          </>
        ))}
      </Slider>
    </Content>
  );
}
