/**
 * NavbarPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Content } from './styledComponents';

export default function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Content>
      <Link to="/">
        <img
          className="logo"
          src="/images/logo-new.png"
          alt="Logo PrestaVale blanco"
        />
      </Link>
      <nav className="actions">
        <Link to="/conviertete-en-distribuidora">
          ¿Quieres ser distribuidora?
        </Link>
        <a href="https://distribuidoras.prestavale.mx">Iniciar sesión</a>
      </nav>
      <IconButton
        aria-label="navigation"
        className="options"
        onClick={handleClick}
      >
        <ListIcon />
      </IconButton>
      <Menu
        id="navigation-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className="nav-item">
          <Link to="/conviertete-en-distribuidora">
            ¿Quieres ser distribuidora?
          </Link>
        </MenuItem>
        <MenuItem className="nav-item">
          <a href="https://distribuidoras.prestavale.mx">Iniciar sesión</a>
        </MenuItem>
      </Menu>
    </Content>
  );
}
