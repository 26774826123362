/**
 * BeADistributorPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';

import { Content, Iframe } from './styledComponents';

export default function BeADistributor() {
  return (
    <Content>
      <Helmet>
        <title>Conviértete en distribuidora</title>
      </Helmet>
      <h2>Únete a nuestro equipo de distribuidoras</h2>
      <h3>
        Conviértete en miembro de la familia PrestaVale y comienza a disfrutar
        de los beneficios que tenemos para tí. Envíanos tu información y nos
        comunicaremos muy pronto contigo.
      </h3>
      <div className="form-section">
        <img
          src="./images/distribuidoras-devices.jpg"
          alt="Dispositivos distribuidoras web"
        />
        <div className="form">
          <Iframe src="https://share.hsforms.com/1YUdmZpJhRzyXIXb-w33QIQs8rzq" />
        </div>
      </div>
    </Content>
  );
}
