import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff82;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
`;

const Loader = () => (
  <Container>
    <CircularProgress size={80} />
  </Container>
);

export default Loader;
