import styled from 'styled-components';
import { media } from '../../helper';

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 624px;
  padding: 24ox;
  text-align: center;

  & .slider-section {
    gap: 18px;
    padding: 24ox;
    display: flex;
    flex-direction: column;
    width: 85%;
    align-items: center;
    justify-content: center;
    margin: auto auto;
    margin-bottom: 32px;
  }

  & .slider-section h4 {
    font-size: 24px;
    font-weight: 500;
  }

  & .slider-section .amount {
    color: #00843d;
    font-size: 44px;
  }

  & .graph-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .graph-section h4 {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 500;
  }

  & .chart-container {
    display: flex;
    flex-direction: row;
  }

  & .chart-container .logo-graph-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    padding: 24px;
  }

  & .chart-container .logo-graph-container > div {
    color: #9c9c9c;
    font-size: 24px;
    font-weight: bold;
  }

  & .chart-container .logo-graph {
    width: 150px;
  }

  ${media.medium} {
    max-width: 780px;

    & .slider-section {
      gap: 6px;
    }

    & h4 {
      font-size: 20px !important;
      text-align: center;
    }

    & .logo-graph-container {
      display: none !important;
    }

    & .chart-container .logo-graph-container > div {
      font-size: 12px;
    }

    & .chart-container .logo-graph {
      margin-bottom: 49px;
    }
  }

  ${media.small} {
    flex-direction: column;
    align-items: center;
    max-width: 400px;

    & h4 {
      font-weight: bold !important;
      margin-top: 16px;
    }
  }

  ${media.xSmall} {
    & .chart-container {
      padding-left: 0;
    }
  }
`;
