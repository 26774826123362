/**
 * CalculatorPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React, { useState } from 'react';
import Numeral from 'numeral';

import { range } from 'lodash';
import Slider from '../Slider';
import {
  BottomSectionCalculator,
  CalculatorContent,
  GridButtonCalculator,
  GridSectionCalculator,
} from '../CommonElements/styledComponents';

export default function Calculator() {
  const [period, setPeriod] = useState(6);
  const [amount, setAmount] = useState(1000);

  const tasaAnual = parseFloat(1 + ((35 / 100) * period) / 24);
  const costByPeriod = (amount * tasaAnual) / period;

  return (
    <CalculatorContent>
      <div style={{ flexDirection: 'column', gap: '5px' }}>
        <div className="amount">{Numeral(amount).format('$ 0,0')}</div>
        <div className="select-title">Monto a solicitar</div>
      </div>
      <div style={{ flexDirection: 'column', gap: '5px' }}>
        <Slider
          value={amount}
          onChange={(e, _value) => setAmount(_value)}
          min={1000}
          max={5000}
          step={500}
        />
        <div className="drag-title">Arrastra para calcular</div>
      </div>

      <div style={{ flexDirection: 'column', gap: '20px' }}>
        <div className="select-title">Selecciona el número de quincenas</div>
        <GridSectionCalculator>
          {range(6, 17, 2).map(e => (
            <GridButtonCalculator
              type="input"
              key={e}
              selected={e === period}
              onClick={() => {
                setPeriod(e);
              }}
            >
              {e}
            </GridButtonCalculator>
          ))}
        </GridSectionCalculator>
      </div>
      <BottomSectionCalculator>
        <div className="subtext">Tu cliente pagaría</div>
        <div className="rightSection">
          <div className="money">{Numeral(costByPeriod).format('$0,0')}</div>
          <div className="money-subtext">Quincenales</div>
        </div>
      </BottomSectionCalculator>
    </CalculatorContent>
  );
}
