import styled from 'styled-components';

export const Content = styled.section`
  margin: 0 auto;
  position: relative;
  padding: 32px 0px;

  & .slick-slider {
    width: 100%;
  }

  & .slick-arrow {
    color: #e0e1e2;
    font-size: 4rem;
  }

  & .custom-slider div:focus {
    outline: none !important;
  }

  & .slick-track {
    padding: 48px 0;
    align-items: center;
    display: flex;
  }

  & .slick-slide {
    .content {
      background-color: #e8f5e9;
      color: #525969;

      .avatar {
        background-color: #02b74f !important;
      }
    }
  }

  & .slick-center {
    .content {
      transform: scale(1.1);
      z-index: 100;
      background-color: #2f7d31 !important;
      color: #ffffff !important;

      .avatar {
        background-color: #ffffff !important;
      }
    }
  }
`;

export const ItemTestimonial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .content {
    background: white;
    border-radius: 8px 8px 0px 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
    width: 290px;
    margin: 0 auto;
    padding: 24px;
    position: relative;
    transition: all 0.25s;

    &:before {
      width: 15px;
      height: 17px;
      position: absolute;
      bottom: -15px;
      right: 0;
      content: '';
      border-radius: 0% 0% 0% 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
      background-color: inherit;
    }

    &:after {
      width: 100%;
      height: 10%;
      border-radius: 0% 100% 0% 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      background-color: inherit;
    }
  }

  & .content .text {
    font-size: 16px;
  }

  & .content .top {
    margin-bottom: 24px;
    display: flex;
  }

  & .content .top .user-name {
    font-size: 20px;
    font-weight: bold;
  }

  & .content .top .level {
    font-size: 12px;
  }

  & .avatar-section {
    position: relative;
    margin-right: 16px;
  }

  & .avatar-section .avatar {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 0px;
    flex-shrink: 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }

  & .avatar-section .level {
    position: absolute;
    right: -6px;
    bottom: 2px;
    width: 16px;
  }
`;
