import styled from 'styled-components';
import { media } from '../../helper';

export const Content = styled.section`
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  grid-gap: 20px 5px;
  height: 100%;

  margin: 0 auto;
  ${media.large} {
    max-width: 860px;
  }

  ${media.medium} {
    flex-direction: column;
    align-items: center;
  }

  ${media.small} {
    grid-template: repeat(5, 1fr) / repeat(2, 1fr);
    grid-gap: 5px 5px;
  }
`;

export const BenefitItem = styled.div`
  color: #181818;
  font-family: Avenir;
  font-size: 24px;
  text-align: left;
  display: flex;
  margin: 12px;

  & img {
    width: 70%;
    height: 70%;
    padding: 10%;
    color: #0c8946;
  }

  .imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }

  .oval {
    display: flex;
    object-fit: contain;
    justify-content: center;
    align-items: center;
    background-color: #e8f5e9;
    border-radius: 100%;
    width: 40px;
    height: 40px;
  }

  .text {
    display: flex;
    align-items: center;
  }

  ${media.medium} {
    font-size: 16px;
  }
`;
