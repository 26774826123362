import styled from 'styled-components';
import { media } from '../../helper';

export const Content = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto auto;

  .inverse {
    transform: scale(-1, 1);
  }

  .vertical {
    display: flex;
    height: 60vh;
    max-height: 600px;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
  }

  .top {
    margin-right: 50%;
  }
  .bubble {
    width: 175px;
    height: 175px;
  }

  & .main-content {
    max-width: 700px;
  }

  & h1 {
    font-size: 72px;
    margin-bottom: 40px;
  }

  & .description {
    font-size: 20px;
    margin-bottom: 56px;
  }

  & .wannabe {
    padding: 24px 32px;
    color: white;
    cursor: pointer;
    background: #02b74f;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    border: none;
    transition: background 0.25s;
  }

  & .wannabe:hover {
    background: #0dd260;
  }

  & .img-banner {
    width: 550px;
  }

  ${media.large} {
    & h1 {
      font-size: 56px;
      margin-bottom: 40px;
    }

    & .img-banner {
      width: 460px;
    }
  }

  ${media.medium} {
    flex-direction: column;
    padding: 5%;
    margin-bottom: 32px;

    & .main-content {
      margin: 48px;
    }

    .vertical {
      display: flex;
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: 0px;
    }
    .top {
      transform: scale(-1, 1);
      margin-right: 0;
    }

    .no-reverse {
      transform: scale(1, 1);
    }

    .reverse-small {
      transform: scale(-1, 1);
    }
    .inverse {
      flex-direction: row-reverse;
    }

    & h1 {
      font-size: 40px;
      margin-bottom: 40px;
    }

    & .img-banner {
      width: 360px;
    }

    & .description {
      font-size: 18px;
    }
  }

  ${media.small} {
    & .main-content {
      text-align: center;
      margin-bottom: 40px;
    }

    .bubble {
      width: 125px;
      height: 125px;
    }

    & .img-banner {
      max-width: 100%;
    }
    /*
    & h1 {
      font-size: 36px;
      margin-bottom: 32px;
    }

    & .img-banner {
      width: 280px;
    }

    & .description {
      font-size: 16px;
      margin-bottom: 32px;
    } */
  }
`;
