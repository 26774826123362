import React from 'react';
import { Link } from 'react-router-dom';
import { BotonQuieroSerDistribuidoraSection } from './styledComponents';

export const BotonQuieroSerDistribuidora = () => (
  <BotonQuieroSerDistribuidoraSection>
    <Link to="/conviertete-en-distribuidora">
      <button className="wannabe" type="button">
        Quiero ser distribuidora
      </button>
    </Link>
  </BotonQuieroSerDistribuidoraSection>
);
