import React from 'react';
import { Helmet } from 'react-helmet';
import { Content, Iframe } from './styledComponents';

export default function AvisoPrivacidad() {
  return (
    <Content>
      <Helmet>
        <title>Dinámica ¡Coloca y gana un viaje!</title>
      </Helmet>
      <Iframe src="https://storage.googleapis.com/prestavale-static/BASES-DINAMICA-CONV2024.pdf" />
    </Content>
  );
}
