import styled from 'styled-components';

export const NavBarContainer = styled.div`
  background: #00843d;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
  padding: 16px;

  img {
    height: 24px;
  }
`;

export const Content = styled.div`
  max-width: 432px;
  padding: 24px 16px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 12px;
    color: #000000;
  }

  .greeting {
    color: #454444;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 8px;
  }

  .introduction {
    color: #919090;
    font-size: 14px;
    margin-bottom: 8px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #f3f3f3;
  }

  .instructions {
    color: #676767;
    font-size: 14px;
    margin-bottom: 24px;
    font-weight: 500;
  }

  .input {
    margin-bottom: 24px;
  }

  .label-description {
    font-size: 12px;
    color: #5d5d5d;
    margin-bottom: 8px;
  }

  .accept-check {
    margin-bottom: 32px;
    margin-top: 24px;
  }

  .accept-check span:last-child {
    font-weight: normal;
    color: #676767;
    font-size: 14px;
  }

  button span {
    text-transform: none;
    font-weight: bold;
  }

  .Mui-disabled {
    background-color: #f3f3f3;
  }

  .MuiInputBase-root.Mui-disabled {
    color: inherit;
  }
`;

export const InputFileContainer = styled.div`
  border: 1px dashed ${props => (props.error ? 'red' : '#d5d5d5')};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 96px;
  justify-content: center;
  align-items: center;
  color: #5d5d5d;
  font-size: 10px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: opacity 0.25s;

  span {
    margin-top: 4px;
  }

  svg {
    width: 16px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const VideoSection = styled.div`
  color: #5d5d5d;
  font-size: 12px;
  cursor: pointer;
  transition: opacity 0.25s;

  .title {
    font-weight: bold;
  }

  .text {
    margin-bottom: 8px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const Input = styled.input`
  display: none;
`;

export const LoadingLabel = styled.div`
  padding: 24px;
  font-size: 18px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #5d5d5d;
  margin-bottom: 8px;
`;

export const ErrorLabel = styled.div`
  font-size: 12px;
  color: red;
`;
