import styled from 'styled-components';
import { media } from '../../helper';

export const Content = styled.section`
  & h2 {
    margin-top: 100px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 60px;
  }

  & h3 {
    font-size: 36px;
    margin-bottom: 40px;
    text-align: center;
  }

  & h4 {
    text-align: center;
    margin-bottom: 32px;
    font-size: 28px;
  }

  & .form {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }

  & .form button {
    margin-left: 16px;
  }

  & .chart-section {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    max-width: 1200px;
    margin: 0 auto;
  }

  & .note {
    font-size: 14px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 80px;
  }

  & .reasons {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1080px;
    margin-bottom: 80px;
  }

  & .reason-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #00843d;
    font-size: 40px;
    max-width: 360px;
    text-align: center;
  }

  & .reason-item > img {
    margin-bottom: 32px;
    height: 100px;
  }

  & .complementary-text {
    font-size: 40px;
    margin-bottom: 40px;
  }

  & .divider {
    max-width: 100%;
    width: 470px;
    background: #00843d;
    height: 6px;
    border-radius: 6px;
    margin-bottom: 56px;
  }

  & .investors-message {
    margin-bottom: 40px;
    color: #181818;
    font-size: 24px;
    text-align: center;
  }

  & .form-section {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    max-width: 360px;
    margin: 0 auto;
  }

  & .form-section > div {
    margin-bottom: 32px;
  }

  ${media.large} {
    & h2 {
      font-size: 56px;
      margin-bottom: 32px;
    }
  }

  ${media.medium} {
    & h2 {
      font-size: 40px;
      margin-bottom: 24px;
      margin-top: 0;
    }

    & h3 {
      font-size: 24px;
    }

    & h4 {
      font-size: 18px;
    }

    & .investors-message {
      font-size: 16px;
    }

    & .chart-section {
      /* display: none; */
      max-width: 700px;
      margin: 0 auto;
    }

    & .complementary-text {
      font-size: 24px;
    }

    & .reason-item {
      font-size: 24px;
    }

    & .reason-item > img {
      margin-bottom: 24px;
      height: 80px;
    }
  }

  ${media.small} {
    & .reasons {
      flex-direction: column;
      align-items: center;
    }

    & .reason-item:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  color: #181818;
  font-size: 36px;
  margin-bottom: 32px;

  & img {
    margin-right: 16px;
  }

  ${media.medium} {
    font-size: 24px;
  }
`;
