/**
 * BenefitsPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';

import { Content, BenefitItem } from './styledComponents';

const benefitsList = [
  {
    text: 'Horarios flexibles',
    image: './images/beneficios/reloj-de-pared.svg',
  },
  {
    text: 'Descuento hasta del 20%',
    image: './images/beneficios/etiqueta-de-precio.svg',
  },
  {
    text: 'Canje los 7 días de la semana',
    image: './images/beneficios/calendario.svg',
    top: '8px',
  },
  {
    text: '9 sucursales disponibles',
    image: './images/beneficios/gazebo.svg',
    top: '8px',
  },
  {
    text: 'Vales electrónicos',
    image: './images/beneficios/vale.svg',
    top: '24px',
  },
  {
    text: 'Préstamos hasta de $10,000',
    image: './images/beneficios/obtener-dinero.svg',
    top: '8px',
  },
  {
    text: 'Aperturamos tu credito hasta por $50,000',
    image: './images/beneficios/billetera.svg',
    top: '15px',
  },
  {
    text: 'Subir de nivel',
    image: './images/beneficios/nivel.svg',
  },
  {
    text: 'Regalos sorpresa',
    image: './images/beneficios/caja-de-regalo.svg',
    top: '5px',
  },
];

export default function Benefits() {
  return (
    <Content>
      {benefitsList.map(item => (
        <BenefitItem key={item.text} top={item.top}>
          <div className="imgWrapper">
            <div className="oval">
              <img src={item.image} alt={item.text} />
            </div>
          </div>
          <div className="text">{item.text}</div>
        </BenefitItem>
      ))}
    </Content>
  );
}
