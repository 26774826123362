import styled from 'styled-components';
import { maxes, media } from '../../helper';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px;
  font-family: Avenir;
  max-width: ${maxes.width};
`;

export const BackGroundContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextSection = styled.div`
  max-width: 400px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  height: 100%;
  text-align: left;
  gap: 20px;

  ${media.medium} {
    max-width: 100vw;
    padding: 24px;
    margin-bottom: 32px;
  }
`;

export const Section = styled.div`
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.medium} {
    padding: 12px;
  }
`;
