import styled from 'styled-components';
import { media } from '../../helper';

export const Content = styled.footer`
  padding: 40px 40px 48px;
  background: black;

  & .logo {
    width: 150px;
  }

  & .options-section {
    display: flex;
    margin-top: 48px;
  }

  & .options-section > div {
    display: flex;
    flex-direction: column;
  }

  & .options-section > div:not(:last-child) {
    margin-right: 120px;
  }

  & .options-section > div > a {
    font-size: 18px;
    margin-bottom: 32px;
    color: white;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: opacity 0.25s;
  }

  & .options-section > div > a:hover {
    opacity: 0.7;
  }

  & .download-label {
    font-size: 18px;
    color: white;
    margin-bottom: 32px;
    font-weight: bold;
  }

  & .app-img {
    height: 52px;
  }

  ${media.medium} {
    & .options-section > div:not(:last-child) {
      margin-right: 40px;
    }
  }

  ${media.small} {
    & .options-section > div:not(:last-child) {
      margin-right: 24px;
    }
  }

  ${media.xSmall} {
    & .options-section {
      flex-direction: column;
    }

    & .options-section > div:not(:last-child) {
      margin-right: 0;
    }
  }
`;
