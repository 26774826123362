/* eslint-disable func-names */
import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';

import './index.css';
import App from './App';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  LogRocket.init('b2gejo/prestavale-core');
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
  document.addEventListener(
    'copy',
    function(event) {
      event.clipboardData.setData(
        'text/plain',
        'No se permite copiar en esta página web',
      );
      event.preventDefault();
    },
    false,
  ),
  document.addEventListener(
    'paste',
    function(event) {
      event.clipboardData.setData(
        'text/plain',
        'No se permite pegar en esta página web',
      );
      event.preventDefault();
    },
    false,
  ),
);
