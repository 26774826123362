import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import config from '../../config';
import { Content } from './styles';
import Loader from '../Loader';

const numberRegex = /^\d+$/;

const AddressDialog = ({ open, onClose, onSaveAddress }) => {
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [suburb, setSuburb] = useState('');
  const [suburbsOptions, setSuburbsOptions] = useState([]);
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (postalCode.length === 5) {
      fetchData();
    } else {
      setCity('');
      setState('');
      setSuburb('');
      setSuburbsOptions([]);
    }
  }, [postalCode]);

  useEffect(() => {
    setPostalCode('');
    setCity('');
    setState('');
    setSuburb('');
    setSuburbsOptions([]);
    setStreet('');
    setNumber('');
  }, [open]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(`${config.api.url}/colonias`, {
        params: {
          filter: {
            where: {
              codigoPostal: postalCode,
            },
          },
        },
      });
      const items = response?.data || [];
      const [firstItem] = items;
      if (!firstItem) return;
      const { nomMunicipio, nomEstado, nomColonia } = firstItem;
      setCity(nomMunicipio);
      setState(nomEstado);
      setSuburb(nomColonia);
      setSuburbsOptions(items);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAddress = () => {
    const address = {
      calle: street,
      ciudad: city,
      estado: state,
      colonia: suburb,
      codigoPostal: postalCode,
      numeroExterior: number,
    };
    onSaveAddress(address);
  };

  const disabled =
    !postalCode ||
    postalCode.length < 5 ||
    !city ||
    !state ||
    !suburb ||
    !street ||
    !number;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <Content>
        <IconButton
          style={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <h2>Ingresar dirección</h2>
        <TextField
          id="postal-code"
          value={postalCode}
          label="Código postal"
          variant="filled"
          onChange={e => {
            const { value } = e.target;
            if (!value || numberRegex.test(value)) {
              setPostalCode(value);
            }
          }}
          fullWidth
          inputProps={{ maxLength: 5 }}
        />
        <TextField
          id="city"
          value={city}
          onChange={e => setCity(e.target.value)}
          label="Ciudad"
          variant="filled"
          fullWidth
        />
        <TextField
          id="state"
          value={state}
          onChange={e => setState(e.target.value)}
          label="Estado"
          variant="filled"
          fullWidth
        />
        <FormControl variant="filled" fullWidth>
          <InputLabel id="suburb-label">Colonia</InputLabel>
          <Select
            labelId="suburb-label"
            id="suburb"
            value={suburb}
            onChange={event => setSuburb(event.target.value)}
          >
            {suburbsOptions.map(c => (
              <MenuItem value={c.nomColonia} key={c.id}>
                {c.nomColonia}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="street"
          value={street}
          label="Calle"
          variant="filled"
          onChange={e => setStreet(e.target.value)}
          fullWidth
        />
        <TextField
          id="number"
          value={number}
          label="Número"
          variant="filled"
          onChange={e => {
            const { value } = e.target;
            if (!value || numberRegex.test(value)) {
              setNumber(value);
            }
          }}
          fullWidth
          inputProps={{ maxLength: 5 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveAddress}
          disabled={disabled}
          size="large"
          fullWidth
          className="save-button"
        >
          Guardar dirección
        </Button>
      </Content>
      {loading && <Loader />}
    </Dialog>
  );
};

AddressDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveAddress: PropTypes.func,
};

export default AddressDialog;
