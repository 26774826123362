/**
 * WhoWeArePage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';

import { Content, StoryLine, StoryItem } from './styledComponents';

export default function WhoWeAre() {
  return (
    <Content>
      <Helmet>
        <title>Nosotros</title>
      </Helmet>
      <h2 style={{ marginTop: 16 }}>¿Quiénes somos?</h2>
      <h3>
        Somos una empresa de crédito que nos dedicamos a prestar dinero por
        medio del modelo de vales, prestamos a nuestros clientes créditos desde
        $1,000 a $10,000 pesos para pagar a un plazo de 6 a 14 quincenas.
      </h3>
      <h2>Historia</h2>
      <StoryLine>
        <span />
        <StoryItem left={false}>
          <div className="left-green-section" />
          <div className="left-green-circle">
            <div className="white-circle">2009</div>
          </div>
          Somos un grupo empresarial que inicio sus actividades en el giro
          comercial de zapatos y accesorios para dama.
        </StoryItem>
        <StoryItem left>
          <div className="left-green-section" />
          <div className="left-green-circle">
            <div className="white-circle">2010</div>
          </div>
          Iniciamos un proceso de transformación del negocio para orientar la
          venta hacia el consumidor de crédito.
        </StoryItem>
        <span />
        <span />
        <StoryItem left={false}>
          <div className="left-green-section" />
          <div className="left-green-circle">
            <div className="white-circle">2012</div>
          </div>
          En este año se forma un segmento de negocio que es distribuido a
          través de mujeres calificadas con experiencia en créditos.
        </StoryItem>
        <StoryItem left>
          <div className="left-green-section" />
          <div className="left-green-circle">
            <div className="white-circle">2018</div>
          </div>
          Gracias a nuestra red de distribuidoras, decidimos incursionar en
          préstamos en efectivo “PrestaVale”
        </StoryItem>
        <span />
      </StoryLine>
    </Content>
  );
}
