import styled from 'styled-components';

export const Content = styled.div`
  padding: 32px 24px;
  width: 360px;
  max-width: 100%;

  h2 {
    margin-bottom: 32px;
    font-size: 24px;
  }

  & > *:not(h2) {
    margin-bottom: 16px;
  }

  .save-button {
    margin: 16px 0 0;
  }

  button span {
    text-transform: none;
    font-weight: bold;
  }
`;
