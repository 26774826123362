/**
 * CalculatorPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React, { useState } from 'react';
import { get, head, range, toNumber } from 'lodash';
import Numeral from 'numeral';

import Slider from '../Slider';
import {
  BottomSectionCalculator,
  CalculatorContent,
  GridButtonCalculator,
  GridSectionCalculator,
} from '../CommonElements/styledComponents';

const tabulator = {
  '1000': {
    '6': 239,
    '8': 190,
    '10': 162,
  },
  '2000': {
    '8': 375,
    '10': 312,
    '12': 266,
  },
  '3000': {
    '8': 555,
    '10': 474,
    '12': 420,
  },
  '4000': {
    '10': 599,
    '12': 512,
    '14': 467,
  },
  '5000': {
    '10': 754,
    '12': 639,
    '14': 582,
  },
  '6000': {
    '12': 793,
    '14': 697,
  },
  '7000': {
    '10': 1080,
    '12': 930,
  },
  '8000': {
    '14': 934,
    '16': 850,
  },
  '9000': {
    '14': 1078,
    '16': 975,
  },
  '10000': {
    '14': 1192,
    '16': 1098,
    '18': 1009,
  },
};

export default function Calculator() {
  const [amount, setAmount] = useState(1000);
  const [period, setPeriod] = useState(6);

  const [minPeriod, maxPeriod] = (() => {
    switch (amount) {
      case 1000:
        return [6, 10];
      case 2000:
        return [8, 12];
      case 3000:
        return [8, 12];
      case 4000:
        return [10, 14];
      case 5000:
        return [10, 14];
      case 6000:
        return [12, 14];
      case 7000:
        return [10, 12];
      case 8000:
        return [14, 16];
      case 9000:
        return [14, 16];
      case 10000:
        return [14, 18];
      default:
        return [6, 10];
    }
  })();

  const price = get(tabulator, `['${amount}']['${period}']`, 0);

  return (
    <CalculatorContent>
      <div style={{ flexDirection: 'column', gap: '5px' }}>
        <div className="amount">{Numeral(amount).format('$ 0,0')}</div>
        <div className="select-title">Monto a solicitar</div>
      </div>
      <div style={{ flexDirection: 'column', gap: '5px' }}>
        <Slider
          value={amount}
          onChange={(e, val) => {
            setPeriod(toNumber(head(Object.keys(tabulator[val]))) || 6);
            setAmount(val);
          }}
          min={1000}
          max={10000}
          step={1000}
        />
        <div className="drag-title">Arrastra para calcular</div>
      </div>

      <div style={{ flexDirection: 'column', gap: '20px' }}>
        <div className="select-title">Selecciona el número de quincenas</div>
        <GridSectionCalculator>
          {range(minPeriod, maxPeriod + 1, 2).map(e => (
            <GridButtonCalculator
              type="input"
              key={e}
              selected={e === period}
              onClick={() => {
                setPeriod(e);
              }}
            >
              {e}
            </GridButtonCalculator>
          ))}
        </GridSectionCalculator>
      </div>
      <BottomSectionCalculator>
        <div className="subtext">Pagarías solo</div>
        <div className="rightSection">
          <div className="money">{Numeral(price).format('$0,0')}</div>
          <div className="money-subtext">Quincenales</div>
        </div>
      </BottomSectionCalculator>
    </CalculatorContent>
  );
}
