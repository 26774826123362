import React from 'react';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { Content, ContainerDialogTerms } from './styledComponents';

const options = {
  // eslint-disable-next-line consistent-return
  replace: domNode => {
    if (domNode.attribs && domNode.attribs.class === 'remove') {
      return <></>;
    }
  },
};

export default function AvisoPrivacidad() {
  return (
    <Content>
      <Helmet>
        <title>
          Términos y Condiciones de la Dinámica de Captación de Nuevas Cuentas
        </title>
      </Helmet>
      <ContainerDialogTerms>
        <div>
          {parse(
            '<h2>Términos y Condiciones de la Dinámica de Captación de Nuevas Cuentas</h2>' +
              '<p>1.⁠ ⁠Objeto de la Dinámica<br><br>Esta dinámica tiene como objetivo incentivar la apertura de nuevas cuentas en los niveles Plata, Oro, Platinum y Doble Platinum, recompensando a los participantes que logren cumplir con las metas de colocación de fondos durante los primeros cuatro meses.</p>' +
              '<p>2.⁠ ⁠Niveles de Cuenta y Recompensas<br><br>Dependiendo del nivel de la cuenta abierta, los usuarios podrán obtener recompensas en efectivo si logran cumplir las siguientes condiciones dentro de los primeros cuatro (4) meses desde la fecha de apertura de la cuenta:</p>' +
              'Nivel Plata: Si colocan 35,000 pesos o más en los primeros cuatro meses, recibirán una recompensa de 8,000 pesos en efectivo.<br>' +
              'Nivel Oro: Si colocan 64,000 pesos o más en los primeros cuatro meses, recibirán una recompensa de 12,000 pesos en efectivo.<br>' +
              'Nivel Platinum y Doble Platinum: Si colocan 96,000 pesos o más en los primeros cuatro meses, recibirán una recompensa de 18,000 pesos en efectivo.' +
              '<p>3.⁠ ⁠Límites Iniciales de Crédito<br><br>Cada cuenta iniciará con los siguientes límites de crédito: </p>' +
              'Plata: 20,000 pesos.<br>' +
              'Oro: 40,000 pesos.<br>' +
              'Platinum/Doble Platinum: 60,000 pesos.<br>' +
              '<p>4.⁠ ⁠Incremento de Límites<br><br>Los usuarios podrán recibir incrementos en sus límites de crédito en función de su comportamiento de pago. Aquellos que vayan pagando puntualmente podrán acceder a incrementos de límite de crédito, lo que les permitirá cumplir con las metas de colocación establecidas para cada nivel de cuenta.</p>' +
              '<p>5.⁠ ⁠Condiciones para la Recompensa<br><br>Para poder recibir la recompensa, el usuario debe cumplir con las siguientes condiciones:</p>' +
              'Haber colocado los montos requeridos de acuerdo al nivel de cuenta en un plazo máximo de cuatro (4) meses desde la apertura de la cuenta.<br>' +
              'Mantener un comportamiento de pago puntual y sin morosidades durante el periodo.<br>' +
              'Las cuentas que hayan tenido incrementos de límite de crédito deberán seguir cumpliendo con los requisitos de buen comportamiento de pago.<br>' +
              '<p>6.⁠ ⁠Validez de la Campaña<br><br>Esta campaña será válida únicamente para todas las cuentas nuevas que se abran antes del 15 de diciembre de 2024. Las cuentas abiertas después de esa fecha no serán elegibles para esta promoción.</p>' +
              '<p>7.⁠ ⁠Aplicabilidad Geográfica<br><br>La presente dinámica aplica exclusivamente a las cuentas abiertas en Ciudad Obregón y Durango. Las cuentas abiertas en otras ciudades no serán elegibles para esta promoción.</p>' +
              '<p>8.⁠ ⁠Pago de la Recompensa<br><br>El pago de las recompensas se realizará en un solo depósito en efectivo dentro de los 30 días posteriores al cumplimiento de los requisitos. El pago será depositado directamente en la cuenta del usuario.</p>' +
              '<p>9.⁠ ⁠Restricciones<br><br>Esta dinámica es válida únicamente para nuevas cuentas abiertas dentro del periodo de la promoción y en las ciudades mencionadas.</p>' +
              'Las cuentas que presenten algún tipo de irregularidad, comportamiento fraudulento o incumplimiento de los términos podrán ser descalificadas de la promoción.<br>' +
              'No se podrán transferir ni acumular las recompensas de un nivel a otro.<br>' +
              '<p>10.⁠ ⁠Modificación de Términos<br><br>La empresa se reserva el derecho de modificar o cancelar esta dinámica en cualquier momento, sin previo aviso. Las modificaciones aplicarán únicamente a nuevas cuentas y no afectarán a quienes ya hayan iniciado el proceso.</p>' +
              '<p>11.⁠ ⁠Aceptación de Términos<br><br>Al participar en esta dinámica, los usuarios aceptan expresamente los términos y condiciones aquí establecidos.</p>',
            options,
          )}
        </div>
      </ContainerDialogTerms>
    </Content>
  );
}
