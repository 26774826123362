import styled from 'styled-components';
import { maxes, media } from '../../helper';

export const Content = styled.section`
  margin: auto -24px -80px -24px;

  .centered {
    max-width: 95vw;
    margin: auto auto;
  }
  & h2 {
    margin-top: 32px;
    text-align: center;
    font-size: 60px;
  }

  & h3 {
    font-size: 36px;
    margin-bottom: 40px;
    text-align: center;
  }

  ${media.large} {
    & h2 {
      font-size: 56px;
    }
  }

  ${media.medium} {
    & h2 {
      font-size: 40px;
    }

    & h3 {
      font-size: 18px;
    }
  }

  ${media.medium} {
    & h2 {
      font-size: 32px;
    }

    & h3 {
      font-size: 16px;
    }
  }
`;

export const BenefitsSection = styled.section`
  width: 100%;
  max-width: 95vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  font-family: Avenir;
  margin 0 auto;
`;

export const CustomCard = styled.div`
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(142, 142, 142, 0.5);
  padding: 48px;
  max-width: 90vw;
`;

export const MaxedContainer = styled.div`
  max-width: ${maxes.width};
  padding: 24px;
`;
