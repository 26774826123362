/* eslint-disable no-restricted-properties */
/**
 * InvestorsPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Chart from 'react-google-charts';
import Axios from 'axios';
import { isEmail, isMobilePhone } from 'validator';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Testimonials from '../../components/Testimonials';

import { Content, Benefit } from './styledComponents';

const testimonialsList = [
  {
    name: 'Jorge',
    avatar: './images/logo-new-p.png',
    text: 'Para mi inversión en Presta Vale es como un sueldo más',
    level: 'Inversionista desde 2020',
    showLevelIcon: false,
  },
  {
    name: 'Hermán',
    avatar: './images/logo-new-p.png',
    text:
      'Yo quise por dejar el dividendo mes con mes para que mi dinero se multiplique',
    level: 'Inversionista desde 2020',
    showLevelIcon: false,
  },
  {
    name: 'Roberto',
    avatar: './images/logo-new-p.png',
    text: 'En PrestaVale en 3 años se dobla mi inversión',
    level: 'Inversionista desde 2020',
    showLevelIcon: false,
  },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Investors() {
  const [amount, setAmount] = useState(1000);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  const handleCreateProspect = async () => {
    try {
      if (!isMobilePhone(phone)) {
        setErrorText('Ingrese un teléfono válido');
        return;
      }

      if (!isEmail(email)) {
        setErrorText('Ingrese un correo válido');
        return;
      }

      const body = {
        name,
        email,
        phone,
      };
      const response = await Axios.get(
        'https://us-central1-prestavale-landing.cloudfunctions.net/postProspect',
        {
          params: body,
        },
      );
      if (response) {
        setSuccessText('¡Solicitud enviada con éxito!');
        setName('');
        setEmail('');
        setPhone('');
      }
    } catch (e) {
      setErrorText('Ocurrió un error. Intente de nuevo');
    }
  };

  const disabled =
    !name || !email || !phone || Number.isNaN(phone) || phone.length < 10;

  return (
    <Content>
      <Helmet>
        <title>Invierte con nosotros</title>
      </Helmet>
      <h2>Invierte con nosotros y sé dueño de tu propio negocio</h2>
      <h3>Simula tu inversión</h3>
      <div className="form">
        <TextField
          id="amount"
          value={amount}
          label="¿Cuánto quieres invertir?"
          variant="filled"
          onChange={e => setAmount(e.target.value)}
        />
        <Button variant="contained" color="primary" size="large">
          Simular
        </Button>
      </div>
      <h4>Rendimiento anual</h4>
      <div className="chart-section">
        <Chart
          width="100%"
          height="460px"
          chartType="ColumnChart"
          loader={<div>Cargando...</div>}
          data={[
            ['', 'Rendimiento'],
            ['1 Año', Number(amount) * Math.pow(1.2, 1)],
            ['2 Años', Number(amount) * Math.pow(1.2, 2)],
            ['3 Años', Number(amount) * Math.pow(1.2, 3)],
            ['4 Años', Number(amount) * Math.pow(1.2, 4)],
            ['5 Años', Number(amount) * Math.pow(1.2, 5)],
          ]}
          options={{
            // Material design options
            chart: {
              title: '',
              subtitle: '',
            },
            hAxis: {
              title: '',
              minValue: 0,
              format: 'decimal',
            },
            colors: ['#00843D'],
            legend: { position: 'none' },
          }}
          // For tests
          rootProps={{ 'data-testid': '2' }}
        />
      </div>
      <div className="note">
        *De las utilidades obtenidas será necesario una retención de una sola
        exhibición del 12% para pagar el impuesto.
      </div>
      <h3>¿Por qué invertir con nosotros?</h3>
      <div className="reasons">
        <div className="reason-item">
          <img src="./images/idea.svg" alt="" />
          <div>+ 8 años de experiencia</div>
        </div>
        <div className="reason-item">
          <img src="./images/proteger.svg" alt="" />
          <div>Cartera vencida menor a 1%</div>
        </div>
        <div className="reason-item">
          <img src="./images/dinero.svg" alt="" />
          <div>+ 120 MDP Valor de proyectos</div>
        </div>
      </div>
      <div className="complementary-text">
        Tu dinero será administrado por un fidecomiso que se obliga a pagar
        primero tus intereses y después devolver el capital a la empresa.
      </div>
      <div className="divider"></div>
      <Benefit>
        <img src="./images/logo-new-p.png" alt="Logo PrestaVale pequeño" />
        Accionista invierte en acciones clase III
      </Benefit>
      <Benefit>
        <img src="./images/logo-new-p.png" alt="Logo PrestaVale pequeño" />
        Presta Vale otorga créditos a distribuidoras
      </Benefit>
      <Benefit>
        <img src="./images/logo-new-p.png" alt="Logo PrestaVale pequeño" />
        Distribuidoras otorga créditos a clientes finales
      </Benefit>
      <Benefit>
        <img src="./images/logo-new-p.png" alt="Logo PrestaVale pequeño" />
        Cliente final paga a distribuidora
      </Benefit>
      <Benefit>
        <img src="./images/logo-new-p.png" alt="Logo PrestaVale pequeño" />
        Distribuidora paga a Presta Vale y se envía por mandato dinero a
        Fidecomiso
      </Benefit>
      <Benefit>
        <img src="./images/logo-new-p.png" alt="Logo PrestaVale pequeño" />
        Fidecomiso paga a inversionistas
      </Benefit>
      <Benefit>
        <img src="./images/logo-new-p.png" alt="Logo PrestaVale pequeño" />
        Fidecomiso envía remanente para prestar de nuevo a clientes finales
      </Benefit>
      <Benefit>
        <img src="./images/logo-new-p.png" alt="Logo PrestaVale pequeño" />
        Acceso a portal para ver tu inversión
      </Benefit>
      <Benefit>
        <img src="./images/logo-new-p.png" alt="Logo PrestaVale pequeño" />
        Retira tu inversión cuando quieras con un click
      </Benefit>
      <h2>Ellos ya están ganando</h2>
      <Testimonials list={testimonialsList} />
      <h2>¿Necesitas más información?</h2>
      <div className="investors-message">
        Conviértete en inversionita de la familia PrestaVale y comienza a
        disfrutar de los beneficios que tenemos para tí. Envíanos tu información
        y nos comunicaremos muy pronto contigo.
      </div>
      <div className="form-section">
        <TextField
          id="name"
          value={name}
          label="Nombre"
          variant="filled"
          onChange={e => setName(e.target.value)}
        />
        <TextField
          id="email"
          value={email}
          label="Correo"
          variant="filled"
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          id="phone"
          value={phone}
          label="Número de celular"
          variant="filled"
          type="tel"
          onChange={e => setPhone(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={disabled}
          onClick={handleCreateProspect}
        >
          Enviar
        </Button>
      </div>
      <Snackbar
        open={Boolean(successText)}
        autoHideDuration={6000}
        onClose={() => setSuccessText('')}
      >
        <Alert onClose={() => setSuccessText('')} severity="success">
          {successText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(errorText)}
        autoHideDuration={6000}
        onClose={() => setErrorText('')}
      >
        <Alert onClose={() => setErrorText('')} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
    </Content>
  );
}
