/* eslint-disable no-console */
/* eslint-disable func-names */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { get, isEmpty } from 'lodash';
import { isCreditCard } from 'validator';

import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-html5-camera-photo/build/css/index.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Loader from '../../components/Loader';
import AddressDialog from '../../components/AddressDialog';

import config from '../../config';
import { Content, NavBarContainer, Label } from './styledComponents';

const formatAddress = address => {
  try {
    const number = address.numeroExterior ? ` #${address.numeroExterior}` : '';
    const postalCode = address.codigoPostal
      ? ` C.P. ${address.codigoPostal}`
      : '';
    const colonia = address.colonia ? ` Col. ${address.colonia}.` : '';
    const city = address.ciudad ? ` ${address.ciudad}` : '';
    const state = address.estado ? `, ${address.estado}.` : '';
    return `${address.calle ||
      ''}${number}${postalCode}${colonia}${city}${state}`;
  } catch (error) {
    return '';
  }
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TextMaskCustom = props => {
  const { inputRef, placeholder, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[4-5]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      placeholder={placeholder}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
};

const EditarPerfil = props => {
  const token = get(props, 'match.params.token', '');
  const [card, setCard] = useState('');
  const [cardTouched, setCardTouched] = useState(false);
  const [confirmCardTouched, setConfirmCardTouched] = useState(false);
  const [confirmCard, setConfirmCard] = useState('');
  const [bank, setBank] = useState('');
  const [banksOptions, setBanksOptions] = useState([]);
  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [clientInfo, setClientInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingFull, setLoadingFull] = useState(true);
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [perfilEditado, setPerfilEditado] = useState('');
  const [lastDigits, setLastDigits] = useState('');
  const [showEditCard, setShowEditCard] = useState(true);
  const [addressOpen, setAddressOpen] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [address, setAddress] = useState(false);
  const [addressToSave, setAddressToSave] = useState(null);
  const [notFound, setNotFound] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {});

  const fetchData = async () => {
    try {
      let response = await Axios.get(`${config.api.url}/bancos`);
      const banks = get(response, 'data', []) || [];
      setBanksOptions(banks);

      response = await Axios.post(
        `${config.api.url}/clientes/get-perfil-por-token`,
        { token },
      );

      if (!get(response, 'data')) {
        setNotFound(true);
      }

      const clientAddress = !isEmpty(response.data.domicilio)
        ? response.data.domicilio
        : {};

      setHasAddress(!isEmpty(clientAddress));
      setAddress(clientAddress);

      const digits = get(response, 'data.tarjeta', '');
      if (digits) {
        setCard(digits);
        setLastDigits(`**** **** **** ${digits.substring(12)}`);
        setCardTouched(true);
      } else {
        setInputsDisabled(false);
        setShowEditCard(false);
      }

      const bankId = get(response, 'data.bancoId', '');
      if (bankId) {
        setBank(bankId);
      }

      setClientInfo(get(response, 'data', {}));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingFull(false);
    }
  };

  const handleEditarPerfil = async () => {
    try {
      setLoadingFull(true);

      setButtonDisabled(true);

      const response = await Axios.post(
        `${config.api.url}/clientes/guardar-edicion-perfil`,
        {
          tarjeta: card.replaceAll(' ', ''),
          token,
          bankId: bank,
          domicilio: !isEmpty(addressToSave) ? addressToSave : address,
        },
      );

      const isSuccess = response.data.success;

      if (!isSuccess) {
        setErrorText('Error actualizando informacion');
        setButtonDisabled(false);
        return;
      }

      setSuccessText('Informacion actualizada con éxito');
      setPerfilEditado('Informacion actualizada ¡Gracias por tu preferencia!');
    } catch (error) {
      setErrorText('Error actualizando tu informacion');
      setButtonDisabled(false);
    } finally {
      setLoadingFull(false);
    }
  };

  const handleActivateFields = () => {
    setCardTouched(false);
    setInputsDisabled(false);
    if (inputsDisabled) {
      setBank('');
      setCard('');
    }
  };

  const handleSaveAddress = _address => {
    setAddressToSave(_address);
    setAddressOpen(false);
  };

  const disabled =
    buttonDisabled ||
    !termsAgreed ||
    !isCreditCard((card || '').replaceAll(' ', '')) ||
    (!inputsDisabled && card !== confirmCard) ||
    !bank ||
    (isEmpty(address) && !addressToSave);

  const errorCard = cardTouched && !isCreditCard(card);
  const errorConfirmCard = confirmCardTouched && confirmCard !== card;

  return (
    <>
      <NavBarContainer>
        <Link to="/">
          <img
            className="logo"
            src="/images/logo-new.png"
            alt="Logo PrestaVale blanco"
          />
        </Link>
      </NavBarContainer>
      <Content>
        <h1>Editar Informacion</h1>
        {loading && <div>Cargando...</div>}
        {!notFound && <div></div>}
        {notFound && <div>Este token no existe o el link es incorrecto.</div>}

        {!loading && !notFound && (
          <>
            <div className="greeting">{`¡Hola, ${get(
              clientInfo,
              'nombres',
              '',
            )}!`}</div>
            <div className="introduction">
              Aqui podras editar tu informacion
            </div>
            {Boolean(perfilEditado) && (
              <div className="greeting">{perfilEditado}</div>
            )}
            {!perfilEditado && (
              <>
                <div className="instructions">
                  Verifica que todos los campos sean correctos
                </div>
                <Label style={{ marginBottom: 2 }}>
                  <span style={{ marginBottom: showEditCard ? 0 : 8 }}>
                    Número de tarjeta
                  </span>
                  {showEditCard && (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleActivateFields}
                    >
                      Editar tarjeta
                    </Button>
                  )}
                </Label>
                {inputsDisabled ? (
                  <TextField
                    id="card-hidden"
                    className="input"
                    error={errorCard}
                    helperText={errorCard && 'Ingrese una tarjeta válida'}
                    value={lastDigits}
                    placeholder="Número de tarjeta"
                    variant="outlined"
                    autoFocus
                    disabled={inputsDisabled}
                  />
                ) : (
                  <>
                    <TextField
                      id="card"
                      className="input"
                      error={errorCard}
                      helperText={errorCard && 'Ingrese una tarjeta válida'}
                      value={card}
                      placeholder="Número de tarjeta"
                      variant="outlined"
                      onChange={e => setCard(e.target.value)}
                      onBlur={() => setCardTouched(true)}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        inputProps: {
                          placeholder: 'Ingresa los 16 dígitos de tu tarjeta',
                        },
                      }}
                      autoFocus
                      disabled={inputsDisabled}
                    />
                    <TextField
                      id="card"
                      className="input"
                      error={errorConfirmCard}
                      helperText={
                        errorConfirmCard && 'Las tarjetas no coinciden'
                      }
                      value={confirmCard}
                      placeholder="Confirme número de tarjeta"
                      variant="outlined"
                      onChange={e => setConfirmCard(e.target.value)}
                      onBlur={() => setConfirmCardTouched(true)}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        inputProps: {
                          placeholder: 'Confirme número de tarjeta',
                        },
                      }}
                      disabled={inputsDisabled}
                    />
                  </>
                )}
                <Label>Banco</Label>
                <FormControl variant="outlined" className="input">
                  <Select
                    labelId="bank-label"
                    id="bank"
                    value={bank}
                    onChange={event => setBank(event.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Banco' }}
                    style={{ color: bank ? '#212121' : '#a2a2a2' }}
                    disabled={inputsDisabled}
                  >
                    <MenuItem value="" disabled>
                      Banco
                    </MenuItem>
                    {banksOptions.map(c => (
                      <MenuItem value={c.id} key={c.id}>
                        {c.nombre}
                      </MenuItem>
                    ))}
                    <FormHelperText>Banco</FormHelperText>
                  </Select>
                </FormControl>
                <Label style={{ marginBottom: 10 }}>
                  <span style={{ marginBottom: showEditCard ? 0 : 8 }}>
                    Dirección
                  </span>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setAddressOpen(true)}
                  >
                    Editar dirección
                  </Button>
                </Label>
                <div>
                  {hasAddress || addressToSave ? (
                    <>
                      <div>{formatAddress(addressToSave || address)}</div>
                    </>
                  ) : (
                    <>
                      <div style={{ marginBottom: 12 }}>
                        Debes ingresar tu dirección
                      </div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setAddressOpen(true)}
                        fullWidth
                        size="large"
                      >
                        Ingresar dirección
                      </Button>
                    </>
                  )}
                </div>

                <FormControlLabel
                  control={<Checkbox name="accept" color="primary" />}
                  label="Aceptas términos y condiciones"
                  className="accept-check"
                  onChange={event => setTermsAgreed(event.target.checked)}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleEditarPerfil}
                  disabled={disabled}
                >
                  Guardar Cambios
                </Button>
              </>
            )}
          </>
        )}
      </Content>
      <Snackbar
        open={Boolean(successText)}
        autoHideDuration={6000}
        onClose={() => setSuccessText('')}
      >
        <Alert onClose={() => setSuccessText('')} severity="success">
          {successText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(errorText)}
        autoHideDuration={6000}
        onClose={() => setErrorText('')}
      >
        <Alert onClose={() => setErrorText('')} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
      <AddressDialog
        open={addressOpen}
        onClose={() => setAddressOpen(false)}
        onSaveAddress={handleSaveAddress}
      />
      {loadingFull && <Loader />}
    </>
  );
};

export default EditarPerfil;
