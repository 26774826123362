import styled from 'styled-components';
import { media } from '../../helper';

export const Content = styled.section`
  & h2 {
    margin-top: 100px;
    margin-bottom: 48px;
    text-align: center;
    font-size: 60px;
  }

  & h3 {
    font-size: 32px;
    margin-bottom: 40px;
    max-width: 730px;
    font-weight: 500;
    line-height: 60px;
  }

  & .content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  & .content .right-section {
    padding-left: 24px;
  }

  & .benefits {
    max-width: 1200px;
    margin: 0 auto;
  }

  & .content .app-image {
    max-width: 100%;
    width: 440px;
  }

  & .content .available-for {
    margin-bottom: 24px;
    color: #00843d;
    font-size: 40px;
  }

  & .content .apps-section {
    display: flex;
    margin-bottom: 64px;
  }

  & .content .apps-section img {
    height: 68px;
  }

  & .content .apps-section img:first-child {
    margin-right: 32px;
  }

  & .link-distributors {
    font-size: 24px;
    color: #181818;
  }

  ${media.large} {
    & h2 {
      font-size: 56px;
      margin-bottom: 32px;
    }
  }

  ${media.medium} {
    & h2 {
      font-size: 40px;
      margin-bottom: 24px;
      margin-top: 0;
    }

    & h3 {
      margin-top: 32px;
      font-size: 24px;
      line-height: 32px;
    }

    & .content {
      flex-direction: column;
      align-items: center;
    }

    & .content .available-for {
      font-size: 32px;
    }

    & .content {
      margin-bottom: 64px;
    }

    & .benefits {
      max-width: 724px;
    }
  }

  ${media.xSmall} {
    & .content .apps-section img {
      height: 44px;
    }

    & .content .apps-section img:first-child {
      margin-right: 24px !important;
    }
  }
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  color: #181818;
  font-size: 36px;
  margin-bottom: 32px;

  & img {
    margin-right: 16px;
  }

  ${media.medium} {
    font-size: 24px;
  }
`;
