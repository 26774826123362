import { create } from '@incodetech/welcome';

const apiURL = 'https://saas-api.incodesmile.com';
const clientId = 'prestavale470';

const incode = create({
  clientId,
  apiURL,
});

export default incode;
