import styled from 'styled-components';
import { media } from '../../helper';

export const Content = styled.header`
  padding: 24px 32px;
  background: #00843d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;

  & .logo {
    width: 150px;
  }

  & .actions a {
    color: white;
    margin-left: 32px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: opacity 0.25s;
  }

  & .actions a:hover {
    opacity: 0.7;
  }

  & .options {
    display: none;
    color: white;
  }

  & .options svg {
    width: 36px;
    height: 36px;
  }

  ${media.small} {
    padding: 16px;

    & .logo {
      width: 130px;
    }

    & .actions {
      display: none;
    }

    & .options {
      display: block;
    }
  }
`;
