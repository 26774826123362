import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  z-index: 9;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 16px 0;
  justify-content: space-between;
  width: 100%;

  h1 {
    background: black;
    padding: 4px;
    text-align: center;
    font-size: 16px;
    max-width: calc(100% - 120px);
  }

  .reminder {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .countdown-text {
    font-size: 64px;
    text-align: center;
    color: #ffffff;
    text-shadow: 2px 2px 4px black;
  }

  .duration-text {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 2px 2px 4px black;
  }
`;

const RecordButton = styled.button`
  width: 75px;
  height: 75px;
  padding: 16px;
  border-radius: 50%;
  outline: none;
  border: none;
  background-color: #ffffff66;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .stop-indicator {
    width: 100%;
    height: 100%;
    background-color: #e70405;
  }

  .record-indicator {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
  }
`;

const VideoActions = props => {
  const {
    isRecording,
    onStartRecording,
    onStopRecording,
    phrase,
    isCameraOn,
    isConnecting,
    isRunningCountdown,
  } = props;
  const [time, setTime] = useState(3);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let timer = null;
    if (isRunningCountdown) {
      timer = setInterval(() => {
        setTime(_time => (_time === 0 ? 0 : _time - 1));
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRunningCountdown]);

  useEffect(() => {
    let timerDuration = null;
    if (isRecording) {
      timerDuration = setInterval(() => {
        setDuration(_duration => (_duration === 30 ? 30 : _duration + 1));
      }, 1000);
    } else {
      clearInterval(timerDuration);
    }
    return () => clearInterval(timerDuration);
  }, [isRecording]);

  if (!isCameraOn || isConnecting) {
    return <div />;
  }

  return (
    <Container>
      <h1>
        <div className="reminder">
          *Recuerda mostrar tu INE en todo momento*
        </div>
        <div>Frase: {phrase}</div>
      </h1>
      {isRunningCountdown ? (
        <div className="countdown-text">{time}</div>
      ) : (
        <RecordButton
          onClick={isRecording ? onStopRecording : onStartRecording}
        >
          <div
            className={isRecording ? 'stop-indicator' : 'record-indicator'}
          />
        </RecordButton>
      )}
      {isRecording && <div className="duration-text">{duration}s</div>}
    </Container>
  );
};

VideoActions.propTypes = {
  isRecording: PropTypes.bool,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
  phrase: PropTypes.string,
  isCameraOn: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isRunningCountdown: PropTypes.bool,
};

export default VideoActions;
