/**
 * OfficesPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { Content, OfficesList } from './styledComponents';

const containerStyle = {
  width: '100%',
  height: '735px',
  maxHeight: '80vh',
};

const center = {
  lat: 24.198,
  lng: -107.91,
};

const scaledSize = { width: 40, height: 40 };
const anchor = { x: 17, y: 46 };

const itemsCuliacan = [
  {
    name: 'Campiña',
    address:
      'Prolongación Doctor Mora entre Blvd Sabinos y Eucaliptos Num. 1725, Local 1C Col. La Campiña Culiacán , Sinaloa México , C.P. 80060',
    schedule: '10:00 a 20:00',
  },
  {
    name: 'Plaza fiesta',
    address:
      'Av. Lázaro Cárdenas e Insurgentes Num. 970 Sur Col. Los Pinos Culiacán, Sinaloa México , C.P. 80128',
    schedule: '10:00 a 20:00',
  },
  {
    name: 'Oficina',
    address:
      'Av. Lázaro Cárdenas Num. 750 A7 Col. Centro Culiacán, Sinaloa México C. P. 80128',
    schedule: '10:00 a 20:00',
  },
  {
    name: 'Sendero',
    address:
      'Calzada José Limón No. 2545 Nte. Local C18, Col. Humaya. Culiacán, Sinaloa, C.P. 80020',
    schedule: '11:00 a 21:00',
  },
  {
    name: 'Morelos',
    address:
      'José Maria Morelos Num. #481 Col. Centro Sinaloa Culiacán , Sinaloa México , C.P. 80000',
    schedule: '10:00 a 20:00',
  },
  {
    name: 'Walmart México 68',
    address:
      'Walmart Alvaro Obregon, Esq. México 68 Num. 2891 Col. Montebello Culiacán , Sinaloa México , C.P. 80227',
    schedule: '10:00 a 20:30',
  },
  {
    name: 'San Isidro',
    address:
      'Paseo de los Ganaderos No. 1787 pte. Fracc. Lomas de San Isidro, Local 23 y 24. Culiacán, Sinaloa México, C.P. 80197',
    schedule: '11:00 a 21:00',
  },
];

// const itemsMochis = [
//   {
//     name: 'Las Palmas',
//     address:
//       'Av. Alvaro Obregón y Blvd. Antonio Rosales Sin Núm. Local 15B, Plaza Fiesta Las Palmas. Col. Centro Los Mochis, Sinaloa. México, C.P. 81200',
//     schedule: '10:00 a 20:00',
//   },
//   {
//     name: 'Hidalgo centro',
//     address:
//       'Miguel Hidalgo 169 Poniente. Col. Centro Los Mochis, Sinaloa México, C.P. 81200',
//     schedule: '10:00 a 20:00',
//   },
// ];

const itemsObregon = [
  {
    name: 'Ciudad Obregón',
    address:
      'Calle California y Av. Guerrero sol. Sección urbanizable #5 local D-6 y D-6A ciudad Obregón, Sonora México, C.P. 85050',
    schedule: '10:00 a 20:00',
  },
];

export default function Offices() {
  return (
    <Content>
      <Helmet>
        <title>Conoce nuestras sucursales</title>
      </Helmet>
      <h2>Encuentra tu sucursal más cercana</h2>
      <h3>
        Contamos con 8 sucursales en 2 ciudades. Encuentra la más cercana a tí
      </h3>
      <LoadScript googleMapsApiKey="AIzaSyCmtCYjTFlqIq7LeDhV5afIP9NnBvl8THw">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={7}>
          <Marker
            id="campiña"
            position={{ lat: 24.816173, lng: -107.365726 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="plaza-fiesta"
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
            position={{ lat: 24.794528, lng: -107.410775 }}
          />
          <Marker
            id="oficina"
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
            position={{ lat: 24.796956, lng: -107.407187 }}
          />
          <Marker
            id="sendero"
            position={{ lat: 24.825328, lng: -107.42665 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="morelos"
            position={{ lat: 24.808448, lng: -107.398089 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="walmart-68"
            position={{ lat: 24.778916, lng: -107.394846 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="san-isidro"
            position={{ lat: 24.755863, lng: -107.402642 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="las-palmas"
            position={{ lat: 25.79037, lng: -109.001546 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="hidalgo-centro"
            position={{ lat: 25.808304, lng: -109.013788 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="cd-obregon"
            position={{ lat: 27.49054, lng: -109.942044 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
        </GoogleMap>
      </LoadScript>
      <OfficesList>
        <div className="list">
          <div className="title">Culiacán</div>
          {itemsCuliacan.map(item => (
            <div className="item">
              <div className="name">
                <img
                  src="./images/logo-new-p.png"
                  alt="Logo PrestaVale pequeño"
                />
                {item.name}
              </div>
              <div className="text">{item.address}</div>
              <div className="text">{item.schedule}</div>
            </div>
          ))}
        </div>
        <div className="list">
          {/* <div className="title">Los Mochis</div>
          {itemsMochis.map(item => (
            <div className="item">
              <div className="name">
                <img
                  src="./images/logo-new-p.png"
                  alt="Logo PrestaVale pequeño"
                />
                {item.name}
              </div>
              <div className="text">{item.address}</div>
              <div className="text">{item.schedule}</div>
            </div>
          ))} */}
          <div className="title">Cd. Obregón</div>
          {itemsObregon.map(item => (
            <div className="item">
              <div className="name">
                <img
                  src="./images/logo-new-p.png"
                  alt="Logo PrestaVale pequeño"
                />
                {item.name}
              </div>
              <div className="text">{item.address}</div>
              <div className="text">{item.schedule}</div>
            </div>
          ))}
        </div>
      </OfficesList>
    </Content>
  );
}
