import styled from 'styled-components';
import { media } from '../../helper';

export const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const BotonQuieroSerDistribuidoraSection = styled.section`
  & .wannabe {
    padding: 24px 32px;
    color: white;
    cursor: pointer;
    background: #02b74f;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    border: none;
    transition: background 0.25s;
  }

  & .wannabe:hover {
    background: #0dd260;
  }
`;

export const CalculatorContent = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  max-width: 960px;
  font-family: Avenir;
  text-align: center;

  & > div {
    display: flex;
    justify-content: center;
  }

  .amount {
    color: #398245;
    font-size: 40px;
    line-height: 48px;
  }

  .select-title {
    color: #181818;
    font-size: 16px;
    line-height: 19px;
  }

  .drag-title {
    color: #525969;
    font-size: 14px;
    line-height: 17px;
  }

  h4 {
    margin-bottom: 56px;
    font-size: 28px;
    font-weight: 500;
  }

  ${media.medium} {
    gap: 40px;
  }
`;

export const GridSectionCalculator = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 340px;
  min-width: 260px;
`;

export const GridButtonCalculator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.selected ? '#e8f5e9' : '#f2f4f6')};
  border-radius: 8px;
  width: 80px;
  height: 56px;
  ${props => (props.border ? '1px solid #02B74F' : '')}
`;

export const BottomSectionCalculator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  text-align: left;
  margin-bottom: 12px;

  .rightSection {
    .money {
      color: #398245;
      font-family: Avenir;
      font-size: 24px;
      line-height: 29px;
    }

    .money-subtext {
      color: #525969;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .subtext {
    color: #181818;
    font-size: 16px;
    line-height: 19px;
  }
`;
