import styled from 'styled-components';
import { media } from '../../helper';

export const Content = styled.section`
  & h2 {
    margin-top: 100px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 60px;
  }

  & h3 {
    font-size: 36px;
    margin-bottom: 40px;
    text-align: center;
  }

  ${media.large} {
    & h2 {
      font-size: 56px;
      margin-bottom: 32px;
    }
  }

  ${media.medium} {
    & h2 {
      font-size: 40px;
      margin-bottom: 24px;
    }

    & h3 {
      font-size: 18px;
    }
  }
`;

export const StoryLine = styled.div`
  margin-top: 90px;
  background-image: url('./images/linea-tiempo.svg');
  min-height: 920px;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 48px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  & > span {
    width: calc((100% / 2) - 60px);
  }

  @media (max-width: 1100px) {
    & > span {
      width: 300px;
    }
  }

  ${media.medium} {
    & > span {
      width: 200px;
    }
  }

  ${media.small} {
    flex-direction: column;
    margin-top: 40px;
  }

  @media (max-width: 856px) {
    & > span {
      width: 120px;
    }
  }
`;

export const StoryItem = styled.div`
  max-width: 600px;
  width: calc((100% / 2) - 60px);
  min-height: 156px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: ${props => (props.left ? '0 200px 0 14px' : '0 14px 0 200px')};
  // padding: ${props =>
    props.left ? '36px 200px 36px 14px' : '36px 14px 36px 200px'};
  position: relative;
  border-radius: ${props => (props.left ? '0 90px 90px 0' : '90px 0 0 90px')};
  overflow: hidden;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  display: flex;
  margin-bottom: 56px;

  & .left-green-section {
    position: absolute;
    ${props => (props.left ? 'right: 0' : 'left: 0')};
    top: 0;
    height: 100%;
    width: 182px;
    background: #00843D;
  }

  & .left-green-circle {
    position: absolute;
    ${props => (props.left ? 'right: 0' : 'left: 0')};
    top: 0;
    height: 100%;
    width: 182px;
    background: #00843D;
    width: 154px;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 8px;
  }

  & .white-circle {
    background: #ffffff;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }

  @media (max-width: 1100px) {
    width: calc(100% - 100px);
  }

  ${media.small} {
    max-width: 100%;
    width: 100%;
  }

  ${media.xSmall} {
    font-size: 13px;
    padding: ${props => (props.left ? '0 168px 0 14px' : '0 14px 0 168px')};

    & .left-green-section {
      width: 156px;
    }

    & .white-circle {
      font-size: 24px;
    }
  }
`;
