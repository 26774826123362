/**
 * SliderPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

const CustomSlider = withStyles({
  root: {
    color: '#00A14A',
    height: 5,
    padding: '16px 0',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#0BC762',
    marginTop: -8,
    marginLeft: -8,
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 5,
  },
  rail: {
    height: 5,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

function SliderComponent({ value, onChange, id, max, min, ...restProps }) {
  return (
    <CustomSlider
      id={id}
      value={value}
      onChange={onChange}
      aria-labelledby={id}
      max={max}
      min={min}
      {...restProps}
    />
  );
}

SliderComponent.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  id: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
};

SliderComponent.defaultProps = {
  value: 1,
  onChange: () => {},
  id: 'Slider',
  max: 100,
  min: 1,
};

export default SliderComponent;
